import React, { useRef, useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';  // Import all FA icons
import * as Fa6Icons from 'react-icons/fa6';  // Font Awesome 6
import * as AiIcons from 'react-icons/ai';  // Ant Design Icons
import * as BiIcons from 'react-icons/bi';  // Boxicons
import * as BsIcons from 'react-icons/bs';  // Bootstrap Icons
import * as IoIcons from 'react-icons/io5'; // Ionicons 5
import * as Io4Icons from 'react-icons/io';   // Ionicons 4
import * as MdIcons from 'react-icons/md';  // Material Design icons
import * as RiIcons from 'react-icons/ri';  // Remix Icons
import * as TbIcons from 'react-icons/tb';  // Tabler Icons
import * as LuIcons from 'react-icons/lu';  // Lucide Icons
import * as HiIcons from 'react-icons/hi2';  // Heroicons 2
import * as Hi1Icons from 'react-icons/hi';   // Heroicons 1
import * as SiIcons from 'react-icons/si';  // Simple Icons
import * as DiIcons from 'react-icons/di';  // Devicons
import * as GiIcons from 'react-icons/gi';  // Game Icons
import * as VscIcons from 'react-icons/vsc';  // VS Code Icons
import * as ImIcons from 'react-icons/im';  // IcoMoon Free
import * as CgIcons from 'react-icons/cg';    // css.gg
import * as TiIcons from 'react-icons/ti';    // Typicons
import * as GoIcons from 'react-icons/go';    // Github Octicons
import * as WiIcons from 'react-icons/wi';    // Weather Icons
import * as FiIcons from 'react-icons/fi';    // Feather Icons
import * as CiIcons from 'react-icons/ci';    // Circum Icons
import * as GrIcons from 'react-icons/gr';    // Grommet Icons
import * as SlIcons from 'react-icons/sl';    // Simple Line Icons
import * as PiIcons from 'react-icons/pi';    // Phosphor Icons
import * as RxIcons from 'react-icons/rx';    // Radix Icons
import * as LiaIcons from 'react-icons/lia';  // Icons8 Line Awesome
import * as ThIcons from 'react-icons/tfi';   // Themify Icons
import html2canvas from 'html2canvas';

const IconToImage = () => {
  const iconRefs = useRef([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLibrary, setSelectedLibrary] = useState('all');
  const [globalColor, setGlobalColor] = useState('#000000');
  const [currentPage, setCurrentPage] = useState(1);
  const [copiedMessage, setCopiedMessage] = useState('');
  const ICONS_PER_PAGE = 20;

  // Create an array of all icons with their libraries
  const allIcons = [
    ...Object.entries(FaIcons).map(([name, component]) => ({ 
      name: name.replace('Fa', ''), 
      component, 
      library: 'Font Awesome' 
    })),
    ...Object.entries(Fa6Icons).map(([name, component]) => ({ 
      name: name.replace('Fa6', ''), 
      component, 
      library: 'Font Awesome 6' 
    })),
    ...Object.entries(AiIcons).map(([name, component]) => ({ 
      name: name.replace('Ai', ''), 
      component, 
      library: 'Ant Design' 
    })),
    ...Object.entries(BiIcons).map(([name, component]) => ({ 
      name: name.replace('Bi', ''), 
      component, 
      library: 'Boxicons' 
    })),
    ...Object.entries(BsIcons).map(([name, component]) => ({ 
      name: name.replace('Bs', ''), 
      component, 
      library: 'Bootstrap' 
    })),
    ...Object.entries(IoIcons).map(([name, component]) => ({ 
      name: name.replace('Io', ''), 
      component, 
      library: 'Ionicons' 
    })),
    ...Object.entries(MdIcons).map(([name, component]) => ({ 
      name: name.replace('Md', ''), 
      component, 
      library: 'Material' 
    })),
    ...Object.entries(RiIcons).map(([name, component]) => ({ 
      name: name.replace('Ri', ''), 
      component, 
      library: 'Remix' 
    })),
    ...Object.entries(TbIcons).map(([name, component]) => ({ 
      name: name.replace('Tb', ''), 
      component, 
      library: 'Tabler' 
    })),
    ...Object.entries(LuIcons).map(([name, component]) => ({ 
      name: name.replace('Lu', ''), 
      component, 
      library: 'Lucide' 
    })),
    ...Object.entries(HiIcons).map(([name, component]) => ({ 
      name: name.replace('Hi2', ''), 
      component, 
      library: 'Heroicons' 
    })),
    ...Object.entries(SiIcons).map(([name, component]) => ({ 
      name: name.replace('Si', ''), 
      component, 
      library: 'Simple Icons' 
    })),
    ...Object.entries(DiIcons).map(([name, component]) => ({ 
      name: name.replace('Di', ''), 
      component, 
      library: 'Devicons' 
    })),
    ...Object.entries(GiIcons).map(([name, component]) => ({ 
      name: name.replace('Gi', ''), 
      component, 
      library: 'Game Icons' 
    })),
    ...Object.entries(VscIcons).map(([name, component]) => ({ 
      name: name.replace('Vsc', ''), 
      component, 
      library: 'VS Code' 
    })),
    ...Object.entries(ImIcons).map(([name, component]) => ({ 
      name: name.replace('Im', ''), 
      component, 
      library: 'IcoMoon' 
    })),
    ...Object.entries(Io4Icons).map(([name, component]) => ({ 
      name: name.replace('Io', ''), 
      component, 
      library: 'Ionicons 4' 
    })),
    ...Object.entries(Hi1Icons).map(([name, component]) => ({ 
      name: name.replace('Hi', ''), 
      component, 
      library: 'Heroicons 1' 
    })),
    ...Object.entries(CgIcons).map(([name, component]) => ({ 
      name: name.replace('Cg', ''), 
      component, 
      library: 'css.gg' 
    })),
    ...Object.entries(TiIcons).map(([name, component]) => ({ 
      name: name.replace('Ti', ''), 
      component, 
      library: 'Typicons' 
    })),
    ...Object.entries(GoIcons).map(([name, component]) => ({ 
      name: name.replace('Go', ''), 
      component, 
      library: 'Github Octicons' 
    })),
    ...Object.entries(WiIcons).map(([name, component]) => ({ 
      name: name.replace('Wi', ''), 
      component, 
      library: 'Weather Icons' 
    })),
    ...Object.entries(FiIcons).map(([name, component]) => ({ 
      name: name.replace('Fi', ''), 
      component, 
      library: 'Feather' 
    })),
    ...Object.entries(CiIcons).map(([name, component]) => ({ 
      name: name.replace('Ci', ''), 
      component, 
      library: 'Circum Icons' 
    })),
    ...Object.entries(GrIcons).map(([name, component]) => ({ 
      name: name.replace('Gr', ''), 
      component, 
      library: 'Grommet' 
    })),
    ...Object.entries(SlIcons).map(([name, component]) => ({ 
      name: name.replace('Sl', ''), 
      component, 
      library: 'Simple Line' 
    })),
    ...Object.entries(PiIcons).map(([name, component]) => ({ 
      name: name.replace('Pi', ''), 
      component, 
      library: 'Phosphor' 
    })),
    ...Object.entries(RxIcons).map(([name, component]) => ({ 
      name: name.replace('Rx', ''), 
      component, 
      library: 'Radix' 
    })),
    ...Object.entries(LiaIcons).map(([name, component]) => ({ 
      name: name.replace('Lia', ''), 
      component, 
      library: 'Line Awesome' 
    })),
    ...Object.entries(ThIcons).map(([name, component]) => ({ 
      name: name.replace('Tfi', ''), 
      component, 
      library: 'Themify' 
    }))
  ].filter(icon => typeof icon.component === 'function');

  const [iconColors, setIconColors] = useState(
    Object.fromEntries(allIcons.map(icon => [icon.name, globalColor]))
  );

  const handleGlobalColorChange = (color) => {
    setGlobalColor(color);
    // Update all icon colors
    setIconColors(
      Object.fromEntries(allIcons.map(icon => [icon.name, color]))
    );
  };

  const handleColorChange = (name, color) => {
    setIconColors(prev => ({
      ...prev,
      [name]: color
    }));
  };

  const downloadImage = async (index, icon, format) => {
    if (iconRefs.current[index]) {
      try {
        const iconElement = iconRefs.current[index];
        const canvas = await html2canvas(iconElement, {
          backgroundColor: null,
          scale: 4,
          logging: false,
          width: iconElement.offsetWidth,
          height: iconElement.offsetHeight
        });
        
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const ctx = tempCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0);
        
        let imgURL;
        let extension;
        
        switch(format) {
          case 'jpg':
            imgURL = tempCanvas.toDataURL('image/jpeg', 0.9);
            extension = 'jpg';
            break;
          case 'webp':
            imgURL = tempCanvas.toDataURL('image/webp', 0.9);
            extension = 'webp';
            break;
          case 'svg':
            // Note: This is a basic SVG conversion
            const svgString = `
              <svg width="${canvas.width}" height="${canvas.height}" xmlns="http://www.w3.org/2000/svg">
                <image href="${tempCanvas.toDataURL()}" width="100%" height="100%"/>
              </svg>
            `;
            imgURL = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
            extension = 'svg';
            break;
          default:
            imgURL = tempCanvas.toDataURL('image/png');
            extension = 'png';
        }

        const link = document.createElement('a');
        link.href = imgURL;
        link.download = `${icon.library}-${icon.name}-icon.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(`Error converting icon:`, error);
      }
    }
  };

  const filteredIcons = allIcons.filter(icon => {
    const matchesSearch = icon.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesLibrary = selectedLibrary === 'all' || icon.library === selectedLibrary;
    return matchesSearch && matchesLibrary;
  });

  const totalPages = Math.ceil(filteredIcons.length / ICONS_PER_PAGE);
  const paginatedIcons = filteredIcons.slice(
    (currentPage - 1) * ICONS_PER_PAGE,
    currentPage * ICONS_PER_PAGE
  );

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedLibrary]);

  const libraries = ['all', ...new Set(allIcons.map(icon => icon.library))];

  // Add this function to handle copying the icon image to the clipboard
  const copyIconToClipboard = async (index) => {
    if (iconRefs.current[index]) {
      try {
        const iconElement = iconRefs.current[index];
        const canvas = await html2canvas(iconElement, {
          backgroundColor: null,
          scale: 4,
          logging: false,
          width: iconElement.offsetWidth,
          height: iconElement.offsetHeight
        });

        canvas.toBlob((blob) => {
          const item = new ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([item]).then(() => {
            setCopiedMessage('Icon copied to clipboard!');
            setTimeout(() => setCopiedMessage(''), 2000);
          });
        });
      } catch (error) {
        console.error('Failed to copy icon: ', error);
      }
    }
  };

  return (
    <div style={{ fontFamily: 'Roboto, sans-serif', color: '#333' }}>
      {/* Copied message display */}
      {copiedMessage && (
        <div style={{
          position: 'fixed',
          top: '10px',
          right: '10px',
          backgroundColor: '#4caf50',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          zIndex: 1000,
          transition: 'opacity 0.5s ease-in-out',
          opacity: copiedMessage ? 1 : 0
        }}>
          {copiedMessage}
        </div>
      )}

      <div style={{
        position: 'sticky',
        top: 0,
        backgroundColor: '#f8f9fa',
        padding: '20px',
        borderBottom: '1px solid #ddd',
        zIndex: 1000,
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        flexWrap: 'wrap',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <input
          type="text"
          placeholder="Search icons..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            padding: '10px 20px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            width: '250px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        />
        <select
          value={selectedLibrary}
          onChange={(e) => setSelectedLibrary(e.target.value)}
          style={{
            padding: '10px 20px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          {libraries.map(lib => (
            <option key={lib} value={lib}>
              {lib.charAt(0).toUpperCase() + lib.slice(1)}
            </option>
          ))}
        </select>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <label style={{ fontSize: '14px', color: '#666' }}>Global Color:</label>
          <input
            type="color"
            value={globalColor}
            onChange={(e) => handleGlobalColorChange(e.target.value)}
            style={{ 
              cursor: 'pointer',
              width: '30px',
              height: '30px',
              padding: '0',
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          />
        </div>
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          Showing {paginatedIcons.length} of {filteredIcons.length} icons
        </span>
      </div>

      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '1.5rem',
        padding: '20px' 
      }}>
        {paginatedIcons.map((icon, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              border: '1px solid #ddd',
              borderRadius: '12px',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'scale(1.05)'
              }
            }}
            onClick={() => copyIconToClipboard(index)}
          >
            <div
              ref={(el) => (iconRefs.current[index] = el)}
              style={{
                padding: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent'
              }}
            >
              <icon.component size={48} color={iconColors[icon.name]} />
            </div>
            <p style={{ margin: '10px 0', color: '#666', fontSize: '16px', fontWeight: '500' }}>{icon.name}</p>
            <p style={{ margin: '0 0 10px', color: '#888', fontSize: '14px' }}>{icon.library}</p>
            
            <div style={{ 
              margin: '10px 0', 
              display: 'flex', 
              alignItems: 'center', 
              gap: '10px',
              width: '100%',
              justifyContent: 'center'
            }}>
              <input
                type="color"
                value={iconColors[icon.name] || '#000000'}
                onChange={(e) => handleColorChange(icon.name, e.target.value)}
                style={{ 
                  cursor: 'pointer',
                  width: '30px',
                  height: '30px',
                  padding: '0',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
              />
              <div style={{ display: 'flex', gap: '4px' }}>
                {['png', 'jpg', 'webp', 'svg'].map((format) => (
                  <button
                    key={format}
                    onClick={() => downloadImage(index, icon, format)}
                    style={{
                      padding: '4px 8px',
                      backgroundColor: 'transparent',
                      color: '#666',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      fontSize: '12px',
                      transition: 'all 0.2s'
                    }}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = '#f5f5f5';
                      e.target.style.borderColor = '#ccc';
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = 'transparent';
                      e.target.style.borderColor = '#ddd';
                    }}
                  >
                    {format.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        gap: '10px',
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#f8f9fa',
        borderTop: '1px solid #ddd',
        boxShadow: '0 -2px 4px rgba(0,0,0,0.1)'
      }}>
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          style={{
            padding: '10px 20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: currentPage === 1 ? '#e0e0e0' : '#fff',
            cursor: currentPage === 1 ? 'default' : 'pointer',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            transition: 'background-color 0.3s'
          }}
        >
          Previous
        </button>
        <span style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '10px',
          fontSize: '16px',
          fontWeight: '500'
        }}>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          style={{
            padding: '10px 20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: currentPage === totalPages ? '#e0e0e0' : '#fff',
            cursor: currentPage === totalPages ? 'default' : 'pointer',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            transition: 'background-color 0.3s'
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default IconToImage; 