import IconToImage from './IconToImage';
import { GiNinjaMask } from 'react-icons/gi';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const ninjaAppear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
`;

const NinjaIcon = styled(GiNinjaMask)`
  font-size: 2.8rem;
  color: #ff4081;
  animation: ${ninjaAppear} 1.5s ease-out;
  &:hover {
    transform: rotate(360deg);
    transition: transform 0.8s ease-in-out;
  }
`;

const Title = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  font-size: 2.5rem;
  font-family: 'Ninjutsu', 'Segoe UI', system-ui, sans-serif;
  text-shadow: 0 0 10px rgba(255, 64, 129, 0.5),
               0 0 20px rgba(255, 64, 129, 0.3);
  letter-spacing: 0.125rem;
`;

function App() {
  return (
    <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <header style={{
        background: 'linear-gradient(45deg, #1a1a1a, #2d2d2d)',
        color: '#ffffff',
        padding: '1.25rem',
        textAlign: 'center',
        boxShadow: '0 0.125rem 0.5rem rgba(0,0,0,0.2)'
      }}>
        <Title>
          <NinjaIcon />
          Icon Ninja
        </Title>
        <p style={{
          margin: '0.625rem 0 0',
          opacity: 0.9,
          fontSize: '1.1rem',
          color: '#e0e0e0'
        }}>
          Swift and Silent Icon Conversion
        </p>
      </header>
      <IconToImage />
    </div>
  );
}

export default App;